<template>
  <nav class="sf-pagination">
    <div class="block">
      <SfButton
          class="load-more sf-link--primary"
          v-if="showLoadMore"
          data-transaction-name="Pagination - Load More"
          @click="loadMore"
      >
      <span>
        {{ $t('Show more {amount} products', {amount: perPage}) }}
      </span>
      </SfButton>
    </div>
    <div class="block">
      <div :class="prevButtonClasses">
        <SfLink
            v-if="canGoPrev"
            :link="getLinkTo(getPrev)"
            aria-label="Go to previous page"
            data-transaction-name="Pagination - Prev"
            @click="onNavClick(getPrev)"
            :muted="true"
        />
      </div>
      <SfLink
          v-if="showFirst"
          class="sf-pagination__item"
          :class="{ 'sf-pagination__item--was': pagesChain.includes(1)} "
          :link="getLinkTo(1)"
          data-transaction-name="Pagination - Go To First"
          @click="onNavClick(1)"
          :muted="true"
      >
        <span>1</span>
      </SfLink>
      <div
          v-if="showLeftDots"
          class="sf-pagination__item sf-pagination__item--dots"
          :class="{'sf-pagination__item--was': leftDotsWas}">
        ...
      </div>
      <SfLink
          v-for="page in limitedPageNumbers"
          :key="page"
          class="sf-pagination__item"
          :class="pageButtonClasses(page)"
          :link="getLinkTo(page)"
          data-transaction-name="Pagination - Go To Page"
          @click="onNavClick(page)"
          :muted="true"
      >
        <span>{{ page }}</span>
      </SfLink>
      <div
          v-if="showRightDots"
          class="sf-pagination__item sf-pagination__item--dots">
        ...
      </div>
      <SfLink
          v-if="showLast"
          class="sf-pagination__item"
          :link="getLinkTo(total)"
          data-transaction-name="Pagination - Go To Last"
          @click="onNavClick(total)"
          :muted="true"
      >
        <span>{{ total }}</span>
      </SfLink>
      <div :class="nextButtonClasses">
        <SfLink
            v-if="canGoNext"
            :link="getLinkTo(getNext)"
            aria-label="Go to previous next"
            data-transaction-name="Pagination - Go To Next"
            @click="onNavClick(getNext)"
            :muted="true"
        />
      </div>
    </div>
    <ALoadingSpinner
        v-show="isLoading"
        :size="24"
        :weight="3"
        :is-absolute-position="true"
    />
  </nav>
</template>
<script>
import {
  SfButton,
  SfLink
} from '@storefront-ui/vue';
import { isServer } from '@vue-storefront/core/helpers';
import { mapActions, mapState } from 'vuex';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';

export default {
  name: 'OPagination',
  components: {
    ALoadingSpinner,
    SfButton,
    SfLink
  },
  props: {
    /**
     * Total number of pages
     */
    total: {
      type: Number,
      default: 0
    },
    /**
     * Maximum visible pagination items
     */
    visible: {
      type: Number,
      default: 3
    },
    /**
     * Scroll top on page changed
     */
    scrollTop: {
      type: Boolean,
      default: false
    },
    /**
     * Current page number, for non router
     */
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 40
    },
    notScroll: {
      type: Boolean,
      default: false
    },
    isLoadMore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      countRangePages: []
    }
  },
  mounted () {
    this.resetCountRangePages(this.currentPage);
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.ui.pagingLoading
    }),
    pagesChain () {
      if (this.isLoadMore) {
        return [...this.countRangePages, this.currentPage];
      } else {
        return [this.currentPage];
      }
    },
    getPrev () {
      return this.currentPage - 1;
    },
    canGoPrev () {
      return this.getPrev > 0 && !this.pagesChain.some((page) => page < this.currentPage);
    },
    getNext () {
      return this.currentPage + 1;
    },
    canGoNext () {
      return this.getNext <= this.total;
    },
    showFirst () {
      return this.firstVisiblePageNumber > 1;
    },
    showLast () {
      return this.lastVisiblePageNumber < this.total;
    },
    listOfPageNumbers () {
      return Array.from(Array(this.total), (_, i) => i + 1);
    },
    limitedPageNumbers () {
      if (this.total <= this.visible) {
        return this.listOfPageNumbers;
      }
      if (this.currentPage < this.visible - Math.floor(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(0, this.visible);
      }
      if (this.total - this.currentPage < this.visible - Math.ceil(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(this.total - this.visible);
      }
      return this.listOfPageNumbers.slice(
        this.currentPage - Math.ceil(this.visible / 2),
        this.currentPage + Math.floor(this.visible / 2)
      );
    },
    firstVisiblePageNumber () {
      return this.limitedPageNumbers[0];
    },
    lastVisiblePageNumber () {
      return this.limitedPageNumbers[this.limitedPageNumbers.length - 1];
    },
    showLeftDots () {
      return this.firstVisiblePageNumber > 2;
    },
    leftDotsWas () {
      return this.pagesChain.some((page) => page > 1 && page < this.firstVisiblePageNumber);
    },
    showRightDots () {
      return this.total - this.lastVisiblePageNumber > 1;
    },
    prevButtonClasses () {
      return {
        'sf-pagination__item': true,
        'sf-pagination__item--prev': true,
        'sf-pagination__item--prev--disable': !this.canGoPrev
      };
    },
    nextButtonClasses () {
      return {
        'sf-pagination__item': true,
        'sf-pagination__item--next': true,
        'sf-pagination__item--next--disable': !this.canGoNext
      };
    },
    showLoadMore () {
      return this.total > this.currentPage;
    }
  },
  methods: {
    ...mapActions({
      setPagingLoading: 'ui/setPagingLoading'
    }),
    async loadMore () {
      this.$emit('load-more');
      this.setPagingLoading(true);
      this.countRangePages.push(this.getNext);
      await this.changeRoute(this.getLinkTo(this.getNext))
    },
    getLinkTo (page) {
      const query = { ...this.$route.query };

      if (page > 1) {
        query.page = page;
      } else {
        delete query.page;
      }

      return { ...this.$route, query };
    },
    changeRoute (route) {
      return new Promise((resolve, reject) => {
        this.$router.push(
          route,
          () => resolve(),
          (err) => reject(err)
        );
      });
    },
    onNavClick (page) {
      this.$emit('page-changed');
      if (!isServer && !this.notScroll) {
        this.$emit('nav-clicked')
        this.$nextTick(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        });
      }
      this.$router.push(this.getLinkTo(page));
      this.resetCountRangePages(page);
    },
    wasVisited (page) {
      return this.pagesChain.includes(page);
    },
    pageButtonClasses (page) {
      return {
        'sf-pagination__item--current': this.currentPage === page,
        'sf-pagination__item--was': this.wasVisited(page)
      };
    },
    resetCountRangePages (page) {
      this.countRangePages = [page];
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~@storefront-ui/shared/styles/components/molecules/SfPagination.scss";

.sf-pagination {
  background-color: var(--light-gray);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  @media (min-width: $tablet-min) {
    padding: 13px 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }

  &__item {
    color: var(--orange);
    transition: color 0.3s ease-in-out;
    font-size: var(--font-sm);
    margin: 0 var(--spacer-15);
    line-height: 44px;

    &--prev, &--next {
      margin: 0;
    }

    &--dots {
      margin: 0;
    }

    &--prev {
      margin: 0 16px 0 0;
    }

    &--next {
      margin: 0 0 0 16px;
    }

    @media (min-width: $tablet-min) {
      margin: 0 var(--spacer-20);

      &--dots {
        margin: 0 var(--spacer-20);
      }

      &:hover {
        color: var(--orange-hover);
      }

      &.sf-link:hover {
        position: relative;
        span {
          position: relative;
        }

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          content: '';
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 50%;
          background-color: var(--color-zircon);
        }
      }
    }

    &--current {
      color: var(--black);

      position: relative;
      span {
        position: relative;
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        content: '';
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;
        background-color: var(--color-zircon);
      }
    }

    &:not(&--current):not(.sf-link),
    &--was {
      color: var(--black);
    }

    &--was {
      pointer-events: none;
      cursor: not-allowed;
    }

    &--prev,
    &--next {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      &--disable {
        pointer-events: none;
      }

      &:empty {
        position: relative;
        pointer-events: none;
        cursor: not-allowed;
      }

      .sf-link {
        position: relative;
        min-width: var(--spacer-30);
        height: var(--spacer-20);
        &:after {
          left: 50%;
        }
      }

      &:empty,
      .sf-link {
        &:after {
          $border: var(--spacer-2) solid var(--black);
          content: '';
          position: absolute;
          top: 50%;
          width: var(--spacer-3);
          height: var(--spacer-3);
          border-left: $border;
          border-bottom: $border;
        }
      }
    }

    &--prev:empty:after,
    &--prev .sf-link:after {
      transform: translateY(-50%) rotate(45deg);
    }

    &--next:empty:after,
    &--next .sf-link:after {
      transform: translateY(-50%) rotate(225deg);
    }

    &--next--disable,
    &--prev--disable {
      &:after {
        border-color: var(--dark-gray);
      }
    }
  }
}

.sf-link--primary {
  font-size: var(--font-sm);
  padding: var(--spacer-20) 0 0;

  @media (min-width: $tablet-min) {
    padding-top: 0;
    padding-left: var(--spacer-30);
  }
}

.sf-pagination__item--current {
  color: var(--black);
}
.sf-pagination__item:not(.sf-pagination__item--current):not(.sf-link) {
  color: var(--black);
}
.sf-pagination__item {
  &--next--disable,
  &--prev--disable {
    &:after {
      border-color: var(--dark-gray);
    }
  }
}

.block {
  display: flex;
  align-items: center;
}

.load-more {
  padding: 0;
  --button-background: transparent;
  --button-color: var(--orange);
  --buton-font-size: var(--font-sm);
  --button-font-weight: var(--font-normal);
  --button-font-line-height: normal;
}
</style>
